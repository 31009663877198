import React, {useState, useEffect} from 'react';
import cnDesktopBgImage from '../images/cn_d.jpg'
import cnMobileBgImage from '../images/cn_m.jpg'
import enDesktopBgImage from '../images/en_d.jpg'
import enMobileBgImage from '../images/en_m.jpg'
import CNFlag from '../images/cn_flg.png'
import ENFlag from '../images/en_flg.png'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const langOptions = [
  { flg: CNFlag, label: '中文', key: 'cn', bg: cnDesktopBgImage, mobileBg: cnMobileBgImage },
  { flg: ENFlag, label: 'EN', key: 'en', bg: enDesktopBgImage, mobileBg: enMobileBgImage },
]

const CNMain = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [selectedLang, setSelectedLang] = useState(langOptions[0])

  // Function to update window width state
  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    // Add event listener when component mounts
    window.addEventListener('resize', updateWindowWidth)

    // Clean up event listener when component unmounts
    return () => {
      window.removeEventListener('resize', updateWindowWidth)
    }
  }, []) // Empty dependency array ensures this effect runs only once (on mount)

  return (
    <div
      style={{
        backgroundImage: `url(${windowWidth > 600 ? selectedLang.bg : selectedLang.mobileBg})`, // Use the imported image
        backgroundSize: '100% 100%',
        backgroundPosition: 'start',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        color: 'white',
        backgroundColor: '#ccc',
        overFlowX: 'hidden'
      }}
    >
      <div className="w-100">
        <div className={`${windowWidth > 600 ? 'd-flex justify-content-end mt-4 me-3' : 'd-flex mt-4 mx-3'}`}>
          <UncontrolledDropdown className='me-2' direction='down'>
            <DropdownToggle >
              <div className="d-flex align-items-center">
                <img src={selectedLang.flg} width={30} height={20} alt={selectedLang.key}/>
                <div className="mx-2">{selectedLang.label}</div>
              </div>
            </DropdownToggle>
            <DropdownMenu>
              {langOptions.map((item) => (
                <DropdownItem key={item.key}  onClick={() => setSelectedLang(item)}>
                  <div className="d-flex align-items-center">
                    <img src={item.flg} width={30} height={20} alt={item.key} />
                    <div className="mx-2">{item.label}</div>
                  </div>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    </div>
  );
};

export default CNMain;
