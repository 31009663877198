// App.js

import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Main from './components/Main'
import CNMain from './components/CNMain'

function App () {
  return (
    <Router>
      <Switch>
        <Route path='/' component={Main} exact />
        <Route path='/cn' component={CNMain} />
        {/* Other routes */}
      </Switch>
    </Router>
  )
}

export default App
